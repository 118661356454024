@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Oswald:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  width: 100vw;
}

.header {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.header-banner {
  height: 15vh;
  background-color: black;
  color: white;
  display: -webkit-flex;
  display: flex;
  border-bottom: 2px white solid;
  position: fixed;
  width: 100vw;
  z-index: 5;
}

.header-logo-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 24px;
  height: 10vh;
  display: -webkit-flex;
  display: flex;
}

.header-logo {
  height: 10vh;
  margin: auto;
  max-height: 75px;
}

.header-img-wrapper {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.artists-mobile-col {
  display: none;
}

.header-blurb-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  z-index: 4;
  margin-top: 20vh;
  margin-bottom: 10vh;
}

.header-logo-blurb-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 2vw;
  padding-bottom: 2vh;
}

.header-blurb-text {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  line-height: 1;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  color: white;
  margin: auto;
}

.header-img {
  height: 100%;
  width: 100vw;
  object-fit: cover;
  object-position: top;
  position: absolute;
  z-index: 0;
}

.header-text-wrapper {
  height: 30vh;
  display: -webkit-flex;
  display: flex;
  font-family: "Montserrat", sans-serif;
}

.header-text {
  line-height: 1.3;
  display: -webkit-flex;
  display: flex;
  width: 55vw;
  margin: auto;
  font-size: 18px;
}

.navbar-wrapper {
  display: -webkit-flex;
  display: flex;
  height: 6vh;
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0;
  margin-right: 0;
}

.navbar-link-wrapper {
  display: -webkit-flex;
  display: flex;
  margin-right: 5vw;
}

.active-navbar-link {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  color: grey;
  border-bottom: 1px grey solid;
  padding-bottom: 5px;
}

.navbar-link {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.navbar-link:hover {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  color: grey;
  border-bottom: 1px grey solid;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.artists-wrapper {
  display: -webkit-flex;
  display: flex;
  margin: auto;
  width: 85vw;
  padding-top: 20vh;
}

.artists-left-col {
  margin-right: 4vw;
}

.artists-right-col {
  margin-top: 7vh;
  margin-left: 4vw;
}

.artist-card-wrapper {
  width: 40vw;
  height: 35vw;
  max-width: 500px;
  max-height: 475px;
  margin-bottom: 6vh;
}

.artist-card-top {
  height: 90%;
  margin: auto;
}

.artist-card-bottom {
  padding-top: 5px;
  height: 10%;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  font-family: "Oswald", sans-serif;
}

.artist-card-name {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 20px;
  padding-bottom: 5px;
  margin-top: auto;
  margin-bottom: auto;
}

.temp-img {
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 650px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  object-fit: cover;
  object-position: center;
}

.temp-img:hover {
  filter: none;
  -webkit-filter: grayscale(0);
  transition: 500ms;
  cursor: pointer;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.artist-card-btn-group {
  display: -webkit-flex;
  display: flex;
  margin-left: auto;
}

.artist-card-btn {
  width: 2vw;
  height: 2vw;
  max-width: 25px;
  max-height: 25px;
  min-width: 15px;
  min-height: 15px;
  margin: auto;
  margin-left: 2px;
  margin-right: 2px;
  display: -webkit-flex;
  display: flex;
}

.artist-card-btn-link {
  margin: auto;
  text-decoration: none;
  color: white;
  display: -webkit-flex;
  display: flex;
}

.artist-website-btn {
  font-size: 10px;
}

.footer {
  background-color: black;
  color: white;
  height: 25vh;
  display: -webkit-flex;
  display: flex;
}

.footer-mid-mobile {
  display: none;
}

.footer-mid {
  display: -webkit-flex;
  display: flex;
  font-size: 20px;
}

.footer-mid-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  border-radius: 5px;
  font-family: "Oswald", sans-serif;
  background: white;
  border: 1px black solid;
  color: black;
}

.footer-mid-btn:hover {
  cursor: pointer;
}

.footer-mid-btn-text {
  padding: 8px 20px;
  font-size: 22px;
  text-decoration: none;
  color: black;
}

.footer-left {
  width: 30vw;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.footer-right {
  width: 30vw;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

#selector-logo {
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}

.footer-disruptor-btns {
  display: -webkit-flex;
  display: flex;
  margin: auto;
}

.footer-disruptor-logo-wrapper {
  width: 100%;
}

.footer-disruptor-logo {
  width: 50%;
}

.footer-btn {
  width: 25px;
  margin-left: 15px;
  margin-right: 15px;
}

.footer-btn:hover {
  cursor: pointer;
}

.social-logo {
  width: 2vw;
  height: 2vw;
  max-width: 12px;
  max-height: 12px;
  margin: auto;
}

@media screen and (max-width: 1200px) {
  .navbar-wrapper {
    font-size: 20px;
  }

  .header-blurb-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 1050px) {
  .navbar-wrapper {
    font-size: 16px;
  }

  .header-blurb-text {
    font-size: 10px;
  }
}

@media screen and (min-width: 1250px) {
  .artists-left-col {
    margin-left: auto;
    margin-right: 2.5vw;
  }

  .artists-right-col {
    margin-right: auto;
    margin-left: 2.5vw;
  }

  .navbar-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media screen and (min-width: 1300px) {
  .navbar-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media screen and (min-width: 1440px) {
  .artists-left-col {
    margin-left: auto;
  }

  .artists-right-col {
    margin-right: auto;
  }

  .navbar-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media screen and (min-width: 1640px) {
  .navbar-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media screen and (min-width: 1700px) {
  .navbar-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media screen and (max-width: 770px) {
  #header-link {
  }

  .header-logo-blurb-wrapper {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 0;
  }

  .header-blurb-text {
    font-size: 10px;
  }

  .header-blurb-wrapper {
    margin-bottom: 0;
    margin-top: 15vh;
  }

  .header-logo {
    height: 10vh;
    margin: auto;
    max-height: 40px;
  }

  .artists-mobile-col {
    display: block;
    margin: auto;
  }

  .artist-website-btn {
    font-size: 12px;
  }

  .header-text-wrapper {
    text-align: left;
  }

  .footer {
    -webkit-flex-direction: column;
            flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .footer-mid-mobile {
    display: block;
    margin-bottom: 5vh;
    margin-top: 5vh;
  }

  .footer-mid {
    display: none;
  }

  .header-logo-wrapper {
    margin: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .header-img-wrapper {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .footer-left {
    width: auto;
    margin: auto;
    margin-bottom: 5vh;
  }

  .footer-right {
    width: auto;
    margin: auto;
    margin-bottom: 5vh;
  }

  .artists-wrapper {
    display: block;
    width: 100vw;
  }

  .artist-card-wrapper {
    width: 80vw;
    height: 50vh;
    margin-left: auto;
    margin-right: auto;
  }

  .navbar-wrapper {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;
    margin-right: 0;
  }

  .header-text-wrapper {
    height: 25vh;
  }

  .header-text {
    max-width: 90vw;
    width: 500px;
  }

  .artists-left-col {
    display: none;
  }

  .artists-right-col {
    display: none;
  }
}

@media screen and (max-width: 638px) {
  .header-text {
    font-size: 20px;
  }

  .navbar-wrapper {
    font-size: 14px;
  }

  .artist-card-name {
    font-size: 20px;
  }

  .artist-website-btn {
    font-size: 10px;
  }

  .navbar-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
  }

  .header-text {
    width: 85vw;
    margin: auto;
  }

  .artist-card-wrapper {
    width: 85vw;
    height: 35vh;
    margin-bottom: 6vh;
  }
}

@media screen and (max-width: 638px) {
  .header-banner {
    -webkit-flex-direction: column;
            flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .header-logo-blurb-wrapper {
    margin-left: 0;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  .header-blurb-text {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  .navbar-wrapper {
    margin-bottom: 1vh;
  }
}

@media screen and (max-width: 422px) {
  .header-text {
    font-size: 18px;
  }

  .navbar-wrapper {
    font-size: 18px;
  }

  .artist-card-name {
    font-size: 18px;
  }
}

@media screen and (max-width: 396px) {
  .header-text {
    font-size: 16px;
  }

  .navbar-wrapper {
    font-size: 16px;
  }

  .artist-card-name {
    font-size: 16px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

